
  @tailwind base;
  @tailwind components;
  @tailwind utilities;

  @font-face {
    font-family: 'Bebas Neue';
    src:  url("./assets/BebasNeue-Regular.ttf") format('truetype');
  }
  @font-face {
    font-family: 'Inter';
    src:  url("http://fonts.gstatic.com/s/bebasneue/v14/JTUSjIg69CK48gW7PXooxW5rygbi49c.ttf") format('truetype');
  }
  @font-face {
    font-family: 'Open Sauce Sans';
    src:  url("./assets/Fonts/OpenSauceSans-Regular.ttf") format('truetype');
  }
  @font-face {
    font-family: 'Clash Display';
    src: url('./assets/Fonts/ClashDisplay-Regular.ttf') format('truetype');
         font-weight: 400;
         font-display: swap;
         font-style: normal;
  }


.w-full {
  width: 100%;
}

.card-file {
  border: 3px dotted #ddd;
  padding: 20px 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #9b9797;
  transition: all 0.2s;
  position: relative;
}

.card-file:hover {
  color: #282626;
}

.hover-link:hover {
  color: blue;
  border-bottom: 1px solid #ddd;
}

.textarea-transaction {
  height: 34px;
  padding: 10px 5px;
  min-height: 30px;
  border: 1px solid #ddd;
  padding: 3px 5px;
  border-radius: 5px;
  width: 100%;
  font-size: 12px;
}

.table-dinamic {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.table-dinamic td,
.table-dinamic th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 3px 5px;
}

.range-picker {
  display: block;
  width: 100%;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.45;
  color: #6e6b7b;
  appearance: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 5px 10px;
  border-radius: 7px;
  border: 1px solid #ddd;
}

.text-danger {
  color: red;
}

.uppercase {
  text-transform: uppercase;
}

.list-none {
  list-style: none;
}

.p-0 {
  padding: 0;
}

.m-0 {
  margin: 0;
}

.text-right {
  text-align: right !important;
}

.float-left {
  float: left;
}

.border-r {
  border-right: 1px solid #eee;
}

.border-0 {
  border: 0px !important;
}

.text-outline {
  text-shadow: -1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black;
}
   
.custom-checkbox input:checked ~ .custom-check svg {
  display: block;
  color: white;
}

.custom-checkbox .custom-check svg {
  color: #B680ED;
}

.slider::before,
.slider::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
  
.slider-container {
  animation: sliderFeatures 15s linear infinite;
  width: 100%;
}
  
@media screen and (max-width: 480px) {
  .slider-container {
    animation: none !important;
    height: auto !important;
  }
}
    
@keyframes sliderFeatures {
  0% { transform: translateY(0%)}
  25% { transform: translateY(10%)}
  50% { transform: translateY(0%)}
  75% { transform: translateY(-10%)}
  100% { transform: translateY(0%)}
}
  
.slider-2::before,
.slider-2::after {
  content: "";
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 2;
}
  
.slider-container-2 {
  animation: sliderTestimonials 20s linear infinite;
}
.slider-container-2.slide-2 {
  animation: sliderTestimonials2 20s linear infinite;
}
  
@media screen and (max-width: 480px) {
  .slider-container-2 {
    animation: none !important;
    height: auto !important;
  }
}
    
@keyframes sliderTestimonials {
  0% { transform: translateY(0%)}
  50% { transform: translateY(-10%)}
  100% { transform: translateY(0%)}
}

@keyframes sliderTestimonials2 {
  0% { transform: translateY(-10%)}
  50% { transform: translateY(0%)}
  100% { transform: translateY(-10%)}
}
